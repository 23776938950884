// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-prismic-post-de-blog-id-index-js": () => import("./../../../src/pages/blog/{prismicPostDeBlog.id}/index.js" /* webpackChunkName: "component---src-pages-blog-prismic-post-de-blog-id-index-js" */),
  "component---src-pages-contactenos-index-js": () => import("./../../../src/pages/contactenos/index.js" /* webpackChunkName: "component---src-pages-contactenos-index-js" */),
  "component---src-pages-curso-prismic-curso-id-js": () => import("./../../../src/pages/curso/{prismicCurso.id}.js" /* webpackChunkName: "component---src-pages-curso-prismic-curso-id-js" */),
  "component---src-pages-docentes-index-js": () => import("./../../../src/pages/docentes/index.js" /* webpackChunkName: "component---src-pages-docentes-index-js" */),
  "component---src-pages-docentes-prismic-docente-id-index-js": () => import("./../../../src/pages/docentes/{prismicDocente.id}/index.js" /* webpackChunkName: "component---src-pages-docentes-prismic-docente-id-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paquetes-index-js": () => import("./../../../src/pages/paquetes/index.js" /* webpackChunkName: "component---src-pages-paquetes-index-js" */),
  "component---src-pages-paquetes-prismic-paquete-id-index-js": () => import("./../../../src/pages/paquetes/{prismicPaquete.id}/index.js" /* webpackChunkName: "component---src-pages-paquetes-prismic-paquete-id-index-js" */),
  "component---src-pages-preguntas-frecuentes-index-js": () => import("./../../../src/pages/preguntas-frecuentes/index.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-index-js" */),
  "component---src-pages-quienes-somos-experiencia-musical-index-js": () => import("./../../../src/pages/quienes-somos/experiencia-musical/index.js" /* webpackChunkName: "component---src-pages-quienes-somos-experiencia-musical-index-js" */),
  "component---src-pages-quienes-somos-index-js": () => import("./../../../src/pages/quienes-somos/index.js" /* webpackChunkName: "component---src-pages-quienes-somos-index-js" */),
  "component---src-pages-quienes-somos-proceso-educativo-index-js": () => import("./../../../src/pages/quienes-somos/proceso-educativo/index.js" /* webpackChunkName: "component---src-pages-quienes-somos-proceso-educativo-index-js" */)
}

